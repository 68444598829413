class AutoCompleter
  constructor:(@formField) ->
    @source = []

  pushResults:(response, source) ->
    for item in response
      @source.push(item[source]) unless item[source] == null

  bindAutoComplete:() ->
    @source = _.uniq(@source)
    @formField.autocomplete({ source: @source})

$ ->
  indexElements = [$('#q_title_cont'), $('#q_written_by_and_author_name_cont'), $('#q_publisher_cont'), $('#q_rights_granted_cont')]
  newFormElements = [$('#contract_title'), $('#contract_written_by'), $('#contract_publisher'), $('#contract_rights_granted')]
  sources = ['title', 'written_by', 'publisher', 'rights_granted']
  $.get("/contracts.json", (response) ->

    for element,index in indexElements
      el = new AutoCompleter(element)
      el.pushResults(response, sources[index])
      el.bindAutoComplete()

    for element,index in newFormElements
      el = new AutoCompleter(element)
      if sources[index] == 'rights_granted'
        el.source.push('US Print & Ebook', 'US Print', 'US Ebook')
      el.pushResults(response, sources[index])
      el.bindAutoComplete()
  )

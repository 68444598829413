var prompt = false;
var term_of_copyright = false;


$(document).on('turbolinks:load', function () {

    var $termOfCopyright = $("#term-of-copyright");
    var $terminationDate = $("#termination-date");

    window.onbeforeunload = function (e) {
        if (prompt === true) {
            return 'Leaving this page will abort unsaved changes';
        }
    };

    $('.unsaved-changes-warning').change(
        function (event) {
            prompt = true;
        });

    $('.thwart-unsaved-changes-warning').click(
        function (event) {
            if (prompt === true) {
                prompt = false;
            }
        });

    if ($termOfCopyright.hasClass("checked")) {
        term_of_copyright = true;
        $terminationDate.attr("disabled", "disabled");
    }

    $termOfCopyright.on("click", function (event) {
        console.log('this is triggering')
        if (term_of_copyright === false) {
            term_of_copyright = true;
            $terminationDate.val(null);
            $terminationDate.attr("disabled", "disabled");
        } else {
            term_of_copyright = false;
            $terminationDate.removeAttr("disabled", "disabled");
        }
    });

    // pdf upload


    $('.upload-contract').on('click', function (e) {
        //return;
        e.preventDefault();
        e.stopPropagation();
        //console.log('upload clicked');
        var contract_id = $(this).data('contract-id');
        var file_input_id = "pdf-" + contract_id;
        console.log(file_input_id);
        $('#' + file_input_id).trigger('click');

    });

    $('input[type="file"]').on('change',function (e) {
        console.log(e.target.files[0]);
        if(e.target.files[0] == undefined) return;
        var contract_id = $(this).data('contract-id');
        var filename = e.target.files[0].name;
        var filename_div_id = "pdf-filename-" + contract_id;
        var upload_btn_class = "upload-contract-btn-" + contract_id;

        var form_id = "pdf-file-upload-" + contract_id;
        $('#' + filename_div_id).text(filename);
        $('.' + upload_btn_class).attr('disabled', true);
        $('.' + upload_btn_class).text('Uploading...');

        var pdf_form = $('#' + form_id);
        //console.log(pdf_form.attr('action'));

        //return;
        // no form on page? return
        if(pdf_form.length == 0) return;


        $.ajax({
            url: pdf_form.attr('action'),
            dataType: 'json',
            type: 'post',
            contentType: false  ,
            data: new FormData(pdf_form[0]),
            processData: false,
            success: function( data, textStatus, jQxhr ){
                if(data.result == 'success') {
                    var contract_filename_id = 'contract-filename-' + data.contract_id;
                    var view_contract_id = "view-contract-" + data.contract_id;
                    $('#' + contract_filename_id).text(data.filename);
                    $('#' + view_contract_id).attr("href",data.url);
                } else {
                    alert('001: Error uploading file! ' + data.error);

                }
                $('.' + upload_btn_class).attr('disabled', false);
                $('.' + upload_btn_class).text('Update Contract');
                $('.' + upload_btn_class).addClass('updated');
                $('#' + view_contract_id).show();
                $(".delete-contract-btn-"+contract_id).show();
            },
            error: function( jqXhr, textStatus, errorThrown ){
                alert('002: Error uploading file!');

                console.log( errorThrown );
                $('.' + upload_btn_class).attr('disabled', false);
                $('.' + upload_btn_class).text('Update Contract');
            }
        });

    });

    $('.delete-contract').on('click', function (e) {
        //return;
        e.preventDefault();
        e.stopPropagation();
        var contract_id = $(this).data('contract-id');
        var action = $(this).data('delete-action');

        $.ajax({
            url: action,
            //dataType: 'json',
            type: 'get',
            contentType: false  ,
            //data: new FormData(pdf_form[0]),
            //processData: false,
            success: function( data, textStatus, jQxhr ){
                if(data.result != undefined && data.result == 'success') {
                    $(".delete-contract-btn-"+contract_id).hide();
                    $('#contract-filename-'+contract_id).text('No contract file!');
                    $('#pdf-'+contract_id).val('');

                } else {
                    alert('Error deleting file! ' + data.error);

                }

            },
            error: function( jqXhr, textStatus, errorThrown ){
                alert('Error deleting file!');
                console.log( errorThrown );

            }
        });

    });

    $(".remove_reminder").on('click', function (e){
        $("#reminder_" + $(this).data('id')).remove();
    });

    $("#add_reminder").on('click', function(e){
        $.ajax({
            url: '/contracts/empty_reminder',
            type: 'get',
            success: function( data, textStatus, jQxhr ){
                $(data).insertBefore("#empty_reminder_placeholder");
            },
            error: function( jqXhr, textStatus, errorThrown ){
                alert('Failed to add reminder!');
                console.log( errorThrown );
            }
        });
        
    });


});



class dynamicEmail
  constructor: (@base) ->
    @i = 0
    @VALID_EMAIL_REGEX = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  hideErrors: ->
    $('.alert').fadeOut(3000)
  removeAllFormRows: ->
    $('#email_address').val("Enter a Secondary Email")
    $('#secondary-email-submit').attr('disabled', true)
  prependOverlay: ->
    _this = this
    overlay = $("""<div><%= image_tag "ajax-loader.gif", {:class=> "overlay-loader"} %></div>""").css({
      'background-color': '#000',
      'width': @base.width(),
      'height': @base.height(),
      'position': 'absolute',
      'z-index': 20,
      'opacity': 0.7
    })
    @base.prepend(overlay)
    $.post("/author_emails", $('#secondary-email-form').serialize(), (response)->
      if response.hasOwnProperty('errors')
        $('.alert').slideDown(400).delay(5000).slideUp(400)
      else
        for key, value of response.emails
          $('.list-group').append(li).slideDown("slow")

      overlay.remove()
      _this.removeAllFormRows()
    )
  setAjaxListener: (element) ->
    _this = this
    element.click (event)->
      event.preventDefault()
      _this.prependOverlay()
  testForm: () ->
    _this = this
    $submit = $('#secondary-email-submit')
    $submit.attr('disabled', true)
    @base.keyup((e)=>
      @base.find('.form-control').each(->
        text = $(this).val()
        if _this.VALID_EMAIL_REGEX.test(text)
          $submit.attr('disabled', false)
        else
          $submit.attr('disabled', true)
        )
    )

$ ->
  d = new dynamicEmail($('#secondary-emails-container'))
  d.hideErrors()
  d.setAjaxListener($('#secondary-email-submit'))
  d.testForm()


jQuery ->
    $('#myTab a').click (e) ->
      e.preventDefault()
      $(this).tab('show')

    $('#contract_notes').keyup _.throttle((e) ->
          console.log("Keyup here")
        , 200);

    $('.toggle_confirm').submit (e) ->
      e.preventDefault()
      $form = $(this)
      $.ajax({
        type: 'POST',
        url: $form.attr('action'),
        data: $form.serialize()
      }).success( (res) ->
        console.log("Got result back!")
        console.log(res)
        console.log($form)
        console.log($form.children('input'))
        $form.children('input[type=submit]').val(res);
      )


